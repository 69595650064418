.clients__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
}

.clients__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);

  border: 1px solid transparent;

  text-align: center;
}

.clients__item small {
  margin-top: 10rem;
  font-size: 0.7rem;
  color: var(--color-light);
  line-height: 3rem;
}

.clients__item small:hover {
  border-color: var(--color-primary-variant);
}

.clients__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.clients__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.clients__item h3 {
  margin: 1.2rem 0 0rem;
}

.clients__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.client__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
}

.clients__info h3,
h4 {
  text-align: center;
  margin-bottom: 1rem;
  color: var(--color-primary);
}

.clients__info h5 {
  text-align: center;
  margin-bottom: 1rem;
}

#tick {
  color: var(--color-primary);
  margin-right: 0.5rem;
}

/* ####### MEDIA QUERIES (MEDIUM DEVICES) ####### */
@media screen and (max-width: 1024px) {
  .clients__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* ####### MEDIA QUERIES (SMALL DEVICES) ####### */
@media screen and (max-width: 600px) {
  .clients__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
